import React from "react";
import { Link } from "react-router-dom";
import { CONSTANT_NAME, urlMappings } from "../../utils/PropertyResolver"; // Assuming you have URL mappings or path configurations
import logo from "../../assets/images/logo.png";
function Footer() {
  return (
    <>
      <div
        className="container-fluid bg-dark text-white-50 footer pt-5 mt-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-3 col-md-6">
              <img src={logo} alt="" style={{ height: "150px" }} />
            </div>
            <div className="col-lg-3 col-md-6">
              <h5 className="text-white mb-4">Company</h5>
              <Link className="btn btn-link text-white-50" to="/about">
                About Us
              </Link>
              <Link className="btn btn-link text-white-50" to="/contact">
                Contact Us
              </Link>
              <Link className="btn btn-link text-white-50" to="/our-service">
                Our Services
              </Link>
              <Link className="btn btn-link text-white-50" to="/privacy-policy">
                Privacy Policy
              </Link>
              <Link
                className="btn btn-link text-white-50"
                to="/terms-and-conditions"
              >
                Terms & Condition
              </Link>
            </div>

            <div className="col-lg-3 col-md-6">
              <h5 className="text-white mb-4">Contact</h5>
              <p className="mb-2">
                <i className="fa fa-map-marker-alt me-3"></i>{" "}
                {CONSTANT_NAME.ADDRESS}
              </p>
              {/* <p className="mb-2">
                <i className="fa fa-phone-alt me-3"></i>
                {CONSTANT_NAME.MOBILE_NUMBER}
              </p> */}
              <p className="mb-2">
                <i className="fa fa-envelope me-3"></i>
                {CONSTANT_NAME.EMAIL_ID}
              </p>
              {/* <div className="d-flex pt-2">
                <a className="btn btn-outline-light btn-social" href="">
                  <i className="fab fa-twitter"></i>
                </a>
                <a className="btn btn-outline-light btn-social" href="">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a className="btn btn-outline-light btn-social" href="">
                  <i className="fab fa-youtube"></i>
                </a>
                <a className="btn btn-outline-light btn-social" href="">
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div> */}
            </div>
            <div className="col-lg-3 col-md-6">
              <h5 className="text-white mb-4">Social Media</h5>
              <Link className="btn btn-link text-white-50" to={urlMappings.INSTAGRAM_URL}>
                <i className="fab fa-instagram"></i> Instagram
              </Link>
              <Link className="btn btn-link text-white-50" to={urlMappings.FACEBOOK_URL}>
                <i className="fab fa-facebook-f"></i> Facebook
              </Link>
              <Link className="btn btn-link text-white-50" to={urlMappings.YOUTUBE_URL}>
                <i className="fab fa-youtube"></i> Youtube
              </Link>
              <Link className="btn btn-link text-white-50" to={urlMappings.LINKED_IN}>
                <i className="fab fa-linkedin-in"></i> Linkedin
              </Link>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="copyright">
            <div className="row">
              <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                &copy;{" "}
                <Link className="border-bottom" to="/">
                  MV Hiring
                </Link>
                , All Right Reserved.{" "}
              </div>
              <div className="col-md-6 text-center text-md-end">
                <div className="footer-menu">
                  <Link to="/">Home</Link>
                  <Link to="/cookies">Cookies</Link>
                  <Link to="/help">Help</Link>
                  <Link to="/faqs">FAQs</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
