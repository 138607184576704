import React, { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import "./Register.css";
import { useDispatch, useSelector } from "react-redux";
import { registerUser, resetPassword } from "../../redux/slices/auth/authSlice"; // Assuming you have a registerUser thunk action
import toast from "react-hot-toast";

function ResetPassword() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isDisable =
    !password.trim() || password !== confirmPassword || isLoading;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleRegister = async (e) => {
    try {
      setIsLoading(true);
      e.preventDefault();
      const payload = {
        token,
        password,
      };

      await dispatch(resetPassword(payload))
        .unwrap()
        .then(() => {
          setPassword("");
          setConfirmPassword("");
          navigate("/login");
        });
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="register-page">
      <div className="register-container" style={{ maxWidth: "400px" }}>
        <h2 className="register-title">Update Password</h2>
        <form className="register-form" onSubmit={handleRegister}>
          <div className="input-group">
            <label htmlFor="password">
              Password <span className="error">*</span>
            </label>
            <div className="password-wrapper">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                placeholder="Enter your password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <span
                className="toggle-password"
                onClick={togglePasswordVisibility}
              >
                <i
                  className={showPassword ? "fas fa-eye-slash" : "fas fa-eye"}
                ></i>
              </span>
            </div>
          </div>
          <div className="input-group">
            <label htmlFor="confirmPassword">
              Confirm Password <span className="error">*</span>
            </label>
            <input
              type={showPassword ? "text" : "password"}
              id="confirmPassword"
              placeholder="Confirm your password"
              required
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
          <button
            type="submit"
            className={
              isDisable ? "register-button-disable" : "register-button"
            }
            disabled={isDisable}
          >
            {isLoading ? "Loading ..." : "Update"}
          </button>
        </form>
      </div>
    </div>
  );
}

export default ResetPassword;
