import PersonAddIcon from "@mui/icons-material/PersonAdd";
import GroupIcon from "@mui/icons-material/Group";
import PersonIcon from "@mui/icons-material/Person";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

export const sidebarItems = [
  {
    name: "Client Management",
    icon: <GroupIcon />,
    collapsible: true,
    items: [
      {
        name: "Add Company",
        icon: <PersonAddIcon />,
        route: "/admin/create-company",
      },
      { name: "Company List", icon: <PersonIcon />, route: "/company-list" },
    ],
  },
  {
    name: "Job Management",
    icon: <GroupIcon />,
    collapsible: true,
    items: [
      {
        name: "Add Job Category",
        icon: <PersonAddIcon />,
        route: "/admin/add-job-category",
      },
      { name: "Add Job", icon: <PersonAddIcon />, route: "/admin/add-job" },
      { name: "Job List", icon: <PersonIcon />, route: "/admin/job-list" },
    ],
  },
  {
    name: "Contact Form",
    icon: <GroupIcon />,
    collapsible: true,
    items: [
      {
        name: "Contact List",
        icon: <PersonAddIcon />,
        route: "/admin/contact-list",
      },
    ],
  },
  // {
  //   name: "User Management",
  //   icon: <GroupIcon />,
  //   collapsible: true,
  //   items: [
  //     { name: "Add Job", icon: <PersonAddIcon />, route: "/admin/add-job" },
  //     { name: "User List", icon: <PersonIcon />, route: "/user-list" },
  //   ],
  // },

  {
    name: "Application Management",
    icon: <GroupIcon />,
    collapsible: true,
    items: [
      {
        name: "Job Application",
        icon: <PersonAddIcon />,
        route: "/admin/job-application",
      },
      
    ],
  },
];
