import React from 'react'
import ClientForm from './component/ClientForm'

function CreateClient() {
  return (
    <>
      <ClientForm />
    </>
  )
}

export default CreateClient
