import { combineReducers } from "@reduxjs/toolkit";
import authSlice, { logout } from "./slices/auth/authSlice";
import userSlice from "./slices/user/userSlice";
import jobSlice from "./slices/job/jobSlice";
import companySlice from "./slices/company/companySlice";
import contactSlice from "./slices/contactUs/contactusSlice";
import jobApplicationSlice from "./slices/jobApplication/jobApplicationSlice";



const appReducer = combineReducers({
  global: "global",
  auth: authSlice,
  user: userSlice,
  job: jobSlice,
  company: companySlice,
  contact: contactSlice,
  jobApplication: jobApplicationSlice
});

const rootReducer = (state, action) => {
  //clear all data in redux store in initial
  if (action.type === logout.type) state = undefined;
  return appReducer(state, action);
};

export default rootReducer;
