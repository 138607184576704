import React from "react";
import "./WhatsAppButton.css";
import { CONSTANT_NAME } from "../../utils/PropertyResolver";
import whatsappimg from "../../assets/images/whatsapp.png";
const WhatsAppButton = () => {
  const handleClick = () => {
    const phoneNumber = CONSTANT_NAME.MOBILE_NUMBER;
    const message = "Hi";
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;

    // Open WhatsApp
    window.open(url, "_blank");
  };

  return (
    <div className="whatsapp-float" onClick={handleClick}>
      <i className="fab fa-whatsapp whatsapp-icon"></i>
    </div>
  );
};

export default WhatsAppButton;
