import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { applyJob, fetchJobById } from "../../redux/slices/job/jobSlice";
import { formatDateToDDMonthYYYY } from "../../utils/CommonMethod";
import MainLoader from "../loader/MainLoader";
import logo from '../../assets/images/logo.png'
function JobDetails() {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { isLoading, jobDetails } = useSelector((state) => state.job);
  const { isAuthenticated } = useSelector((state) => state.auth);
  const { userProfile } = useSelector((state) => state.user);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    dispatch(fetchJobById(id));
  }, [id, dispatch]);

  const handleJobApply = async () => {
    try {
      setLoader(true);
      if (!isAuthenticated) {
        return navigate("/login");
      }
      if (!userProfile?.isProfileComplete) {
        return navigate("/profile-complete");
      }
      await dispatch(applyJob({ jobId: id })).unwrap();
    } catch (error) {
    } finally {
      setLoader(false);
    }
  };
  return (
    <>
      {isLoading || (loader && <MainLoader />)}
      <div class="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container">
          <div class="row gy-5 gx-4">
            <div class="col-lg-8">
              <div class="d-flex align-items-center mb-5">
                <img
                  class="flex-shrink-0 img-fluid border rounded"
                  src={logo}
                  alt=""
                  style={{ width: "80px", height: "80px" }}
                />
                <div class="text-start ps-4">
                  <h3 class="mb-3">{jobDetails?.jobTitle}</h3>
                  <span class="text-truncate me-3">
                    <i class="fa fa-map-marker-alt text-primary me-2"></i>New
                    {jobDetails?.jobLocation}
                  </span>
                  <span class="text-truncate me-3">
                    <i class="far fa-clock text-primary me-2"></i>{" "}
                    {jobDetails?.jobType}
                  </span>
                  <span class="text-truncate me-0">
                    <i class="far fa-money-bill-alt text-primary me-2"></i>
                    {jobDetails?.salaryRange}
                  </span>
                </div>
              </div>

              <div class="mb-5">
                <h4 class="mb-3">Job description</h4>
                <p>{jobDetails?.jobDescription}</p>
                <h4 class="mb-3">Responsibility</h4>
                <p>{jobDetails?.responsibility}</p>
                {/* <ul class="list-unstyled">
                  <li>
                    <i class="fa fa-angle-right text-primary me-2"></i>Dolor
                    justo tempor duo ipsum accusam
                  </li>
                  <li>
                    <i class="fa fa-angle-right text-primary me-2"></i>Elitr
                    stet dolor vero clita labore gubergren
                  </li>
                  <li>
                    <i class="fa fa-angle-right text-primary me-2"></i>Rebum
                    vero dolores dolores elitr
                  </li>
                  <li>
                    <i class="fa fa-angle-right text-primary me-2"></i>Est
                    voluptua et sanctus at sanctus erat
                  </li>
                  <li>
                    <i class="fa fa-angle-right text-primary me-2"></i>Diam diam
                    stet erat no est est
                  </li>
                </ul> */}
                <h4 class="mb-3">Qualifications</h4>
                <p>{jobDetails?.qualifications}</p>
                {/* <ul class="list-unstyled">
                  <li>
                    <i class="fa fa-angle-right text-primary me-2"></i>Dolor
                    justo tempor duo ipsum accusam
                  </li>
                  <li>
                    <i class="fa fa-angle-right text-primary me-2"></i>Elitr
                    stet dolor vero clita labore gubergren
                  </li>
                  <li>
                    <i class="fa fa-angle-right text-primary me-2"></i>Rebum
                    vero dolores dolores elitr
                  </li>
                  <li>
                    <i class="fa fa-angle-right text-primary me-2"></i>Est
                    voluptua et sanctus at sanctus erat
                  </li>
                  <li>
                    <i class="fa fa-angle-right text-primary me-2"></i>Diam diam
                    stet erat no est est
                  </li>
                </ul> */}
              </div>

              <div class="">
                <h4 class="mb-4">Apply For The Job</h4>

                <div class="row g-3">
                  <div class="col-3">
                    <button
                      class="btn btn-primary w-100"
                      type="submit"
                      onClick={handleJobApply}
                    >
                      Apply Now
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4">
              <div
                class="bg-light rounded p-5 mb-4 wow slideInUp"
                data-wow-delay="0.1s"
              >
                <h4 class="mb-4">Job Summery</h4>
                <p>
                  <i class="fa fa-angle-right text-primary me-2"></i>Published
                  On: {formatDateToDDMonthYYYY(jobDetails?.createdAt)}
                </p>
                <p>
                  <i class="fa fa-angle-right text-primary me-2"></i>Vacancy:
                  {jobDetails?.totalVacancy} Position
                </p>
                <p>
                  <i class="fa fa-angle-right text-primary me-2"></i>Job Nature:
                  {jobDetails?.jobType}
                </p>
                <p>
                  <i class="fa fa-angle-right text-primary me-2"></i>Salary:
                  {jobDetails?.salaryRange}
                </p>
                <p>
                  <i class="fa fa-angle-right text-primary me-2"></i>Location:
                  {jobDetails?.jobLocation}
                </p>
                <p class="m-0">
                  <i class="fa fa-angle-right text-primary me-2"></i>Date Line:
                  {formatDateToDDMonthYYYY(jobDetails?.lastDate)}
                </p>
              </div>
              <div
                class="bg-light rounded p-5 wow slideInUp"
                data-wow-delay="0.1s"
              >
                <h4 class="mb-2">Company Detail</h4>
                <p class="m-0">{jobDetails?.client?.companyBio}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default JobDetails;
