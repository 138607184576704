import React from 'react'
import JobForm from './component/JobForm'
import JobCreationForm from './component/JobForm'

function CreateJob() {
  return (
    <>
      <JobCreationForm />
    </>
  )
}

export default CreateJob
