import React from "react";
import "./CategoryCard.css";
import { Link } from "react-router-dom";

export default function CategoryCard({ data }) {
  return (
    <>
      <Link
        to={`/job-category/${data?.id}`}
        className="col-lg-3 col-sm-6 wow fadeInUp"
        data-wow-delay="0.1s"
      >
        <a className="cat-item rounded p-4" href="">
          <i className={`${data?.icon} fa fa-3x text-primary mb-4`}></i>
          <h6 className="mb-3">{data?.title}</h6>
          <p className="mb-0">{data?.jobCount || 0} vacancy</p>
        </a>
      </Link>
    </>
  );
}
