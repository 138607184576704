import React from "react";
import { getRoleById } from "../../utils/CommonMethod";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CandidateProfileForm from "./component/CandidateProfileForm";

function ProfileCompelete() {
  const { isAuthenticated } = useSelector((state) => state.auth);

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return <>
<CandidateProfileForm/>
  </>;
}

export default ProfileCompelete;
