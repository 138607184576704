import React from "react";
import ServiceCard from "./ServiceCard";
import image1 from '../../assets/images/brand/contract.jpeg'
import image2 from '../../assets/images/brand/download.jpg'
import image7 from '../../assets/images/brand/key.jpeg'
import image3 from '../../assets/images/brand/employee training.jpg'
import image4 from '../../assets/images/brand/payroll.jpeg'
import image5 from '../../assets/images/brand/talent.jpeg'
import image6 from '../../assets/images/brand/pulse-survey.png'
import image8 from '../../assets/images/brand/campus.png'
import image9 from '../../assets/images/brand/mediatest.jpeg'
import image10 from '../../assets/images/brand/josh.jpeg'








function Service() {
  const serviceData = [
    {
      title: "Recruitment and Talent Acquisition",
      image: image5, // Replace with actual image URL
      description:
        "Comprehensive recruitment solutions, from job postings to conducting interviews, ensuring you hire top talent that fits your team.",
    },
    {
      title: "Campus Hiring",
      image: image8, // Replace with actual image URL
      description:
        "Connecting with universities and colleges to identify and recruit promising young professionals for your organization.",
    },
    // {
    //   title: "Contract Staffing",
    //   image: image1, // Replace with actual image URL
    //   description:
    //     "Flexible staffing solutions that allow you to hire skilled professionals on a temporary basis for immediate project needs.",
    // },
    {
      title: "Employee Training",
      image: image9, // Replace with actual image URL
      description:
        "Tailored training programs to empower your employees with the skills they need to excel and grow within your organization.",
    },
    {
      title: "Payroll and Benefits Administration",
      image: image4, // Replace with actual image URL
      description:
        "Handling payroll processing, benefits management, and compliance, ensuring accuracy and timely delivery of compensation.",
    },
    {
      title: "Employee Engagement Programs",
      image: image10, // Replace with actual image URL
      description:
        "Create meaningful engagement initiatives such as employee outings and corporate events to foster team spirit and build morale.",
    },
    {
      title: "Policy Development and Implementation",
      image: image2, // Replace with actual image URL
      description:
        "Developing and implementing clear and well-structured policies to ensure smooth operations and compliance with industry standards.",
    },
    {
      title: "KPI Development and Integration",
      image: image7, // Replace with actual image URL
      description:
        "Creating and integrating SMART Key Performance Indicators (KPIs) to track performance and drive results for your organization.",
    },
    {
      title: "Compliance and Risk Management",
      image: image3, // Replace with actual image URL
      description:
        "Ensuring your organization stays aligned with legal standards, helping you mitigate risks and avoid penalties.",
    },
    {
      title: "PulseCheck Surveys",
      image: image6, // Replace with actual image URL
      description:
        "Customized surveys to gather employee feedback, empowering you to make informed decisions for a positive workplace culture.",
    },
  ];

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          flexWrap: "wrap",
        }}
        className="container"
      >
        {serviceData.map((service, index) => (
          <ServiceCard
            title={service.title}
            image={service.image}
            description={service.description}
            key={index}
          />
        ))}
      </div>
    </>
  );
}

export default Service;
