import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../shared-components/table/Table";
import MainLoader from "../../shared-components/loader/MainLoader";
import {
  fetchContactList,
  markAsRead,
} from "../../redux/slices/contactUs/contactusSlice";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

function ContactUs() {
  const dispatch = useDispatch();
  const { contactList, isLoading, error } = useSelector(
    (state) => state.contact
  );
  const [pageCount, setPageCount] = useState(0);
  const [filter, setFilter] = useState("unread"); // To track the selected filter

  // Memoize the fetchData function to avoid infinite API calls
  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      // Include filter in the query param
      const param = `?page=${pageIndex + 1}&limit=${pageSize}&filter=${
        filter === "all" ? "" : filter
      }`;
      dispatch(fetchContactList(param))
        .then((response) => {
          if (response.payload?.data?.total) {
            setPageCount(Math.ceil(response.payload.data.total / pageSize));
          }
        })
        .catch((err) => console.error("Error fetching contact list:", err));
    },
    [dispatch, filter] // Add filter as a dependency to refetch when it changes
  );

  const handleMarkAsRead = (id) => {
    console.log("Marked as read:", id);
    dispatch(markAsRead({ id }));
  };

  const handleView = (id) => {
    console.log("Viewing message:", id);
    // Add functionality to view the detailed message
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value); // Update the filter value
  };

  const columns = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Mobile",
      accessor: "mobile",
    },
    {
      Header: "Subject",
      accessor: "subject",
    },
    {
      Header: "Message",
      accessor: "message",
    },
    {
      Header: "Created At",
      accessor: "createdAt",
      Cell: ({ value }) => new Date(value).toLocaleDateString(),
    },
    {
      Header: "Updated At",
      accessor: "updatedAt",
      Cell: ({ value }) =>
        value ? new Date(value).toLocaleDateString() : "Unread",
    },
  ];

  // Define the action buttons in the table
  const actionButtons = [
    {
      label: "Mark as Read",
      onClick: (row) => handleMarkAsRead(row.id),
      variant: "contained",
      color: "primary",
      // Only show button if 'updatedAt' is null (message is unread)
      isVisible: (row) => row.deletedAt === null,
    },
  ];

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Contact Us</h1>

      {/* Filter dropdown */}
      <FormControl style={{ width: "200px" }}>
        <InputLabel id="filter-label">Filter</InputLabel>
        <Select
          labelId="filter-label"
          id="filter-select"
          value={filter}
          label="Filter"
          onChange={handleFilterChange} // Handle filter change
        >
          <MenuItem value="all">All Messages</MenuItem>
          <MenuItem value="read">Read Messages</MenuItem>
          <MenuItem value="unread">Unread Messages</MenuItem>
        </Select>
      </FormControl>
      <hr />

      {isLoading && <MainLoader />}
      {error && <p>Error: {error}</p>}
      <Table
        columns={columns}
        data={contactList || []} // Data from Redux
        fetchData={fetchData}
        pageCount={pageCount}
        actionButtons={actionButtons}
      />
    </div>
  );
}

export default ContactUs;
