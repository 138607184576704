import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Login.css";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword, loginUser } from "../../redux/slices/auth/authSlice";
import toast from "react-hot-toast";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const isDisable = !email.trim();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    try {
      setIsLoading(true);
      e.preventDefault();
      const payload = {
        email,
      };
      await dispatch(forgotPassword(payload))
        .unwrap()
        .then(() => {
          // On success, clear the form fields
          setEmail("");

          // Navigate to the desired route, e.g., dashboard
          navigate("/login");
        });
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <h2 className="login-title">Forgot Password</h2>
        <form className="login-form" onSubmit={handleLogin}>
          <div className="input-group">
            <label htmlFor="email">
              Email <span className="error">*</span>
            </label>
            <input
              type="email"
              id="email"
              placeholder="Enter your email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <button
            type="submit"
            className={
              isDisable || isLoading ? "login-button-disable" : "login-button"
            }
            disabled={isDisable || isLoading}
          >
            {isLoading ? "Loading..." : "Login"}
          </button>
        </form>
        <div className="login-links">
          <Link to="/Login">Login</Link>
          <Link to="/register">Create a New Account</Link>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
