// ActionButton.js
import React from "react";
import { Button } from "@mui/material";

function ActionButton({ label, onClick, variant = "contained", color = "primary" }) {
  return (
    <Button variant={variant} color={color} onClick={onClick} sx={{mr:3}}>
      {label}
    </Button>
  );
}

export default ActionButton;
