import React, { useEffect } from "react";
import {
  CardContent,
  TextField,
  Button,
  Grid,
  MenuItem,
  Chip,
  IconButton,
  Tooltip,
  InputAdornment,
} from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { profileCompleteSchema } from "../../../utils/validationSchema";
import FormCardWrapper from "../../../shared-components/cardWrapper/FormCardWrapper";
import SendIcon from "@mui/icons-material/Send";
import UploadIcon from "@mui/icons-material/Upload"; // MUI Upload Icon
import { useDispatch, useSelector } from "react-redux";
import { completeProfile } from "../../../redux/slices/user/userSlice";
import { useNavigate } from "react-router-dom";
import { fetchJobCategories } from "../../../redux/slices/job/jobSlice";

const renderLabel = (label) => (
  <span>
    {label} <span className="error" style={{ color: "red" }}>*</span>
  </span>
);

function CandidateProfileForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { jobCategories } = useSelector((state) => state.job);

  useEffect(()=>{
    dispatch(fetchJobCategories());
  },[])


  const handleSubmit = (values, { resetForm }) => {
    const formData = new FormData();
    Object.keys(values).forEach((key) => {
      if (key === "resume") {
        if (values.resume) {
          formData.append("resume", values.resume);
        }
      } else {
        formData.append(key, values[key]);
      }
    });

    dispatch(completeProfile(formData))
      .unwrap()
      .then(() => {
        resetForm();
        navigate("/");
      })
      .catch((error) => {});
  };

  const today = new Date().toISOString().split("T")[0]; // Get today's date for date validation

  return (
    <FormCardWrapper title={"Profile Completion"}>
      <CardContent>
        <Formik
          initialValues={{
            dateOfBirth: "",
            gender: "",
            address: "",
            highestQualification: "",
            fieldOfStudy: "",
            universityName: "",
            yearOfGraduation: "",
            totalExperience: "",
            currentEmployer: "",
            jobTitle: "",
            noticePeriod: "",
            currentSalary: "",
            expectedSalary: "",
            keySkills: [],
            certifications: [],
            resume: null,
            interestJobType: "" // Add resume to initialValues
          }}
          validationSchema={profileCompleteSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, values, setFieldValue, errors, touched }) => {
            const handleKeyDown = (e, field) => {
              if (e.key === "Enter" && e.target.value) {
                e.preventDefault();
                const newValue = e.target.value;
                setFieldValue(field, [...values[field], newValue]);
                e.target.value = "";
              }
            };

            const handleDelete = (item, field) => {
              setFieldValue(
                field,
                values[field].filter((i) => i !== item)
              );
            };

            return (
              <Form>
                <Grid container spacing={2}>
                  {/* Date of Birth */}
                  <Grid item xs={12} sm={4}>
                    <Field
                      as={TextField}
                      name="dateOfBirth"
                      label={renderLabel("Date of Birth")}
                      type="date"
                      variant="outlined"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        inputProps: { max: today }, // Disable future dates
                      }}
                      error={Boolean(errors.dateOfBirth && touched.dateOfBirth)}
                      helperText={<ErrorMessage name="dateOfBirth" />}
                    />
                  </Grid>

                  {/* Gender */}
                  <Grid item xs={12} sm={4}>
                    <Field
                      as={TextField}
                      select
                      name="gender"
                      label={renderLabel("Gender")}
                      variant="outlined"
                      fullWidth
                      error={Boolean(errors.gender && touched.gender)}
                      helperText={<ErrorMessage name="gender" />}
                    >
                      <MenuItem value="male">Male</MenuItem>
                      <MenuItem value="female">Female</MenuItem>
                      <MenuItem value="other">Other</MenuItem>
                    </Field>
                  </Grid>

                  {/* Resume Upload */}
                  <Grid item xs={12} sm={4}>
                    <Field name="resume">
                      {({
                        field,
                        form: { setFieldValue, touched, errors, values },
                      }) => (
                        <div
                          onClick={() =>
                            document.getElementById("resumeUpload").click()
                          } // Click anywhere to trigger file input
                          // style={{
                          //   border: touched.resume && errors.resume ? "1px solid red" : "1px solid #ccc",
                          //   padding: "16.5px 14px",
                          //   borderRadius: "4px",
                          //   cursor: "pointer",
                          // }}
                        >
                          <input
                            id="resumeUpload"
                            type="file"
                            style={{ display: "none" }} // Hide native input
                            accept=".pdf,.doc,.docx"
                            onChange={(event) => {
                              setFieldValue(
                                "resume",
                                event.currentTarget.files[0]
                              );
                            }}
                          />
                          <TextField
                            fullWidth
                            variant="outlined"
                            label={renderLabel("Upload Resume")}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Tooltip title="Upload Resume">
                                    <IconButton
                                      color="primary"
                                      aria-label="upload resume"
                                      onClick={() =>
                                        document
                                          .getElementById("resumeUpload")
                                          .click()
                                      }
                                    >
                                      <UploadIcon />
                                    </IconButton>
                                  </Tooltip>
                                </InputAdornment>
                              ),
                              readOnly: true,
                              value: values.resume ? values.resume.name : "",
                            }}
                            error={touched.resume && Boolean(errors.resume)}
                            helperText={<ErrorMessage name="resume" />}
                          />
                          {values.resume && (
                            <div style={{ marginTop: "10px" }}>
                              <Chip
                                label={values.resume.name}
                                onDelete={() => setFieldValue("resume", null)}
                                color="primary"
                                style={{ margin: "5px" }}
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </Field>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Field
                      as={TextField}
                      select
                      name="interestJobType"
                      label={renderLabel("Select Interest Job Type")}
                      variant="outlined"
                      fullWidth
                      error={Boolean(errors.interestJobType && touched.interestJobType)}
                      helperText={<ErrorMessage name="interestJobType" />}
                    >
                      {jobCategories?.map((item) => (
                        <MenuItem value={item?.id}>{item?.title}</MenuItem>
                      ))}
                    </Field>
                  </Grid>

                  {/* Address */}
                  <Grid item xs={12} sm={4}>
                    <Field
                      as={TextField}
                      name="address"
                      label="Address"
                      variant="outlined"
                      fullWidth
                      error={Boolean(errors.address && touched.address)}
                      helperText={<ErrorMessage name="address" />}
                    />
                  </Grid>

                  {/* Highest Qualification */}
                  <Grid item xs={12} sm={4}>
                    <Field
                      as={TextField}
                      name="highestQualification"
                      label={renderLabel("Highest Qualification")}
                      variant="outlined"
                      fullWidth
                      error={Boolean(
                        errors.highestQualification &&
                          touched.highestQualification
                      )}
                      helperText={<ErrorMessage name="highestQualification" />}
                    />
                  </Grid>

                  {/* Field of Study */}
                  <Grid item xs={12} sm={4}>
                    <Field
                      as={TextField}
                      name="fieldOfStudy"
                      label={renderLabel("Field of Study")}
                      variant="outlined"
                      fullWidth
                      error={Boolean(
                        errors.fieldOfStudy && touched.fieldOfStudy
                      )}
                      helperText={<ErrorMessage name="fieldOfStudy" />}
                    />
                  </Grid>

                  {/* University Name */}
                  <Grid item xs={12} sm={4}>
                    <Field
                      as={TextField}
                      name="universityName"
                      label="University Name"
                      variant="outlined"
                      fullWidth
                      error={Boolean(
                        errors.universityName && touched.universityName
                      )}
                      helperText={<ErrorMessage name="universityName" />}
                    />
                  </Grid>

                  {/* Year of Graduation */}
                  <Grid item xs={12} sm={4}>
                    <Field
                      as={TextField}
                      name="yearOfGraduation"
                      label="Year of Graduation"
                      variant="outlined"
                      type="number"
                      fullWidth
                      error={Boolean(
                        errors.yearOfGraduation && touched.yearOfGraduation
                      )}
                      helperText={<ErrorMessage name="yearOfGraduation" />}
                    />
                  </Grid>

                  {/* Total Experience */}
                  <Grid item xs={12} sm={4}>
                    <Field
                      as={TextField}
                      name="totalExperience"
                      label="Total Experience (years)"
                      variant="outlined"
                      type="number"
                      fullWidth
                      error={Boolean(
                        errors.totalExperience && touched.totalExperience
                      )}
                      helperText={<ErrorMessage name="totalExperience" />}
                    />
                  </Grid>

                  {/* Current Employer */}
                  <Grid item xs={12} sm={4}>
                    <Field
                      as={TextField}
                      name="currentEmployer"
                      label="Current Employer"
                      variant="outlined"
                      fullWidth
                      error={Boolean(
                        errors.currentEmployer && touched.currentEmployer
                      )}
                      helperText={<ErrorMessage name="currentEmployer" />}
                    />
                  </Grid>

                  {/* Job Title */}
                  <Grid item xs={12} sm={4}>
                    <Field
                      as={TextField}
                      name="jobTitle"
                      label="Job Title"
                      variant="outlined"
                      fullWidth
                      error={Boolean(errors.jobTitle && touched.jobTitle)}
                      helperText={<ErrorMessage name="jobTitle" />}
                    />
                  </Grid>

                  {/* Notice Period */}
                  <Grid item xs={12} sm={4}>
                    <Field
                      as={TextField}
                      name="noticePeriod"
                      label="Notice Period"
                      variant="outlined"
                      fullWidth
                      error={Boolean(
                        errors.noticePeriod && touched.noticePeriod
                      )}
                      helperText={<ErrorMessage name="noticePeriod" />}
                    />
                  </Grid>

                  {/* Current Salary */}
                  <Grid item xs={12} sm={4}>
                    <Field
                      as={TextField}
                      name="currentSalary"
                      label="Current Salary"
                      variant="outlined"
                      type="number"
                      fullWidth
                      error={Boolean(
                        errors.currentSalary && touched.currentSalary
                      )}
                      helperText={<ErrorMessage name="currentSalary" />}
                    />
                  </Grid>

                  {/* Expected Salary */}
                  <Grid item xs={12} sm={4}>
                    <Field
                      as={TextField}
                      name="expectedSalary"
                      label="Expected Salary"
                      variant="outlined"
                      type="number"
                      fullWidth
                      error={Boolean(
                        errors.expectedSalary && touched.expectedSalary
                      )}
                      helperText={<ErrorMessage name="expectedSalary" />}
                    />
                  </Grid>

                  {/* Key Skills */}
                  <Grid item  xs={12} sm={4}>
                    <Field name="keySkills">
                      {() => (
                        <TextField
                          variant="outlined"
                          fullWidth
                          label="Key Skills"
                          onKeyDown={(e) => handleKeyDown(e, "keySkills")}
                          helperText="Press Enter to add"
                        />
                      )}
                    </Field>
                    <div style={{ marginTop: "10px" }}>
                      {values.keySkills.length > 0 &&
                        values.keySkills.map((skill, index) => (
                          <Chip
                            key={index}
                            label={skill}
                            onDelete={() => handleDelete(skill, "keySkills")}
                            color="primary"
                            style={{ margin: "5px" }}
                          />
                        ))}
                    </div>
                  </Grid>

                  {/* Certifications */}
                  <Grid item xs={12} sm={4}>
                    <Field name="certifications">
                      {() => (
                        <TextField
                          variant="outlined"
                          fullWidth
                          label="Certifications"
                          onKeyDown={(e) => handleKeyDown(e, "certifications")}
                          helperText="Press Enter to add"
                        />
                      )}
                    </Field>
                    <div style={{ marginTop: "10px" }}>
                      {values.certifications.length > 0 &&
                        values.certifications.map((certification, index) => (
                          <Chip
                            key={index}
                            label={certification}
                            onDelete={() =>
                              handleDelete(certification, "certifications")
                            }
                            color="primary"
                            style={{ margin: "5px" }}
                          />
                        ))}
                    </div>
                  </Grid>

                  {/* Submit Button */}
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      endIcon={<SendIcon />}
                      disabled={isSubmitting}
                      fullWidth
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </CardContent>
    </FormCardWrapper>
  );
}

export default CandidateProfileForm;
