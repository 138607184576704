import { Box, Card, styled } from '@mui/material'
import React from 'react'

const CardRoot = styled(Card)({
    height:"100%",
    padding:"20px 24px",
 margin:"20px",
 borderRadius:"20px"
});

const CardTitle = styled("div")(({subtitle})=>({
    fontSize: "1rem",
    fontWeight:"500",
    textTransform:"capitalize",
    marginBottom: !subtitle && "16px"
}))
function FormCardWrapper({children, title, subtitle}) {
  return (
    <>
      <CardRoot elevation={6}>
        <CardTitle subtitle={subtitle}>{title}</CardTitle>
        {subtitle && <Box mb={2}>{subtitle}</Box>}
        {children}
      </CardRoot>
    </>
  )
}

export default FormCardWrapper
