import React, { Suspense, lazy } from 'react';
import MainLoader from '../loader/MainLoader';

// A higher-order component for lazy loading
const Loadable = (importFunc) => {
  const LazyComponent = lazy(importFunc);

  return (props) => (
    <Suspense fallback={<div><MainLoader /></div>}>
      <LazyComponent {...props} />
    </Suspense>
  );
};

export default Loadable;
