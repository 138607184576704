import React, { useState } from "react";
import { TextField, Button, Box, Typography } from "@mui/material";
import FormCardWrapper from "../../../shared-components/cardWrapper/FormCardWrapper";
import CategoryIcon from "@mui/icons-material/Category";
import { useDispatch } from "react-redux";
import { createJobCategories } from "../../../redux/slices/job/jobSlice";

function JobCategories() {
  const dispatch = useDispatch();
  const [category, setCategory] = useState("");
  const [icon, setIcon] = useState("");
  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    const payload = {
     title: category,
      icon,
    };
    dispatch(createJobCategories(payload));
  };

  return (
    <FormCardWrapper>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        {/* Title with Icon */}
        <Typography
          variant="h5"
          sx={{ display: "flex", alignItems: "center", mb: 2 }}
        >
          <CategoryIcon sx={{ mr: 1 }} /> Job Category
        </Typography>

        {/* Input Field */}
        <TextField
          label="Category Name"
          variant="outlined"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          sx={{ mb: 2, width: "300px" }}
          required
        />
        <TextField
          label="Icon url"
          variant="outlined"
          value={icon}
          onChange={(e) => setIcon(e.target.value)}
          sx={{ mb: 2, width: "300px" }}
          required
        />

        {/* Submit Button */}
        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
      </Box>
    </FormCardWrapper>
  );
}

export default JobCategories;
