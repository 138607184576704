import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function JobFilter({ setJobType }) {
  // State to track the currently selected job type
  const [selectedType, setSelectedType] = useState('');

  // Handler to prevent link default behavior and update job type
  const handleLinkClick = (e, type) => {
    e.preventDefault();  // Prevent the link from navigating
    setJobType(type);
    setSelectedType(type);  // Update the selected type state
  };

  return (
    <ul className="nav nav-pills d-inline-flex justify-content-center border-bottom mb-5">
      <li className="nav-item">
        <Link
          className={`d-flex align-items-center text-start mx-3 ms-0 pb-3 ${selectedType === '' ? 'active' : ''}`}
          data-bs-toggle="pill"
          onClick={(e) => handleLinkClick(e, '')}
          to="#" // Placeholder link since navigation is prevented
        >
          <h6 className="mt-n1 mb-0">Featured</h6>
        </Link>
      </li>
      <li className="nav-item">
        <Link
          className={`d-flex align-items-center text-start mx-3 pb-3 ${selectedType === 'full time' ? 'active' : ''}`}
          data-bs-toggle="pill"
          onClick={(e) => handleLinkClick(e, 'full time')}
          to="#" // Placeholder link
        >
          <h6 className="mt-n1 mb-0">Full Time</h6>
        </Link>
      </li>
      <li className="nav-item">
        <Link
          className={`d-flex align-items-center text-start mx-3 me-0 pb-3 ${selectedType === 'part time' ? 'active' : ''}`}
          data-bs-toggle="pill"
          onClick={(e) => handleLinkClick(e, 'part time')}
          to="#" // Placeholder link
        >
          <h6 className="mt-n1 mb-0">Part Time</h6>
        </Link>
      </li>
    </ul>
  );
}

export default JobFilter;
