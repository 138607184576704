import React, { useEffect, useState } from "react";
import {
  CardContent,
  Typography,
  TextField,
  Button,
  Grid,
  MenuItem,
} from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { jobCreationSchema } from "../../../utils/validationSchema";
import FormCardWrapper from "../../../shared-components/cardWrapper/FormCardWrapper";
import SendIcon from "@mui/icons-material/Send";
import { useDispatch, useSelector } from "react-redux";
import {
  createJob,
  fetchJobCategories,
  updateJob,
} from "../../../redux/slices/job/jobSlice";
import { useNavigate } from "react-router-dom";
import { fetchCompanyList } from "../../../redux/slices/company/companySlice";
import MainLoader from "../../../shared-components/loader/MainLoader";

const renderLabel = (label) => (
  <span>
    {label} <span className="error">*</span>
  </span>
);

function JobCreationForm({ data, btnLabel }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { jobCategories } = useSelector((state) => state.job);
  const { companyList } = useSelector((state) => state.company);
  const jobCategoreisLoader = useSelector((state) => state.job.isLoading);
  const companyListLoader = useSelector((state) => state.company.isLoading);

  const loader = jobCategoreisLoader || companyListLoader;

  useEffect(() => {
    dispatch(fetchJobCategories());
    dispatch(fetchCompanyList());
  }, [dispatch]);

  const handleSubmit = async (values, { resetForm }) => {
    const payload = {
      jobTitle: values.jobTitle,
      jobLocation: values.jobLocation,
      jobType: values.jobType,
      salaryRange: values.salaryRange,
      jobDescription: values.jobDescription,
      responsibility: values.responsibility,
      qualifications: values.qualification,
      totalVacancy: values.totalVacancy,
      lastDate: values.lastDayToApply,
      companyId: values.company,
      jobCategory: values.category,
    };
    if(btnLabel === 'Update'){
      await dispatch(updateJob({...payload, id: data?.id}))
      .unwrap()
      .then(() => {
      })
      .catch((error) => {});
    }else{
      await dispatch(createJob(payload))
      .unwrap()
      .then(() => {
        resetForm();
      })
      .catch((error) => {});
    }
    
  };

  return (
    <>
      {loader && <MainLoader />}
      <FormCardWrapper title={"Create Job Posting"}>
        <CardContent>
          <Formik
            initialValues={{
              jobTitle: data?.jobTitle || "",
              jobLocation: data?.jobLocation || "",
              jobType: data?.jobType || "",
              salaryRange: data?.salaryRange || "",
              jobDescription: data?.jobDescription || "",
              responsibility: data?.responsibility || "",
              qualification: data?.qualifications || "",
              totalVacancy: data?.totalVacancy || "",
              lastDayToApply: data?.lastDate
                ? new Date(data.lastDate).toISOString().split("T")[0]
                : "",
              company: data?.client?.id || "",
              category: data?.jobCategory || "",
            }}
            validationSchema={jobCreationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, errors, touched }) => (
              <Form>
                <Grid container spacing={2}>
                  {/* Company */}
                  <Grid item xs={12} sm={6}>
                    <Field
                      as={TextField}
                      select
                      name="company"
                      label={renderLabel("Select Company")}
                      variant="outlined"
                      fullWidth
                      error={Boolean(errors.company && touched.company)}
                      helperText={<ErrorMessage name="company" />}
                    >
                      {companyList?.map((item) => (
                        <MenuItem value={item?.id}>
                          {item?.companyName}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>

                  {/* Category */}
                  <Grid item xs={12} sm={6}>
                    <Field
                      as={TextField}
                      select
                      name="category"
                      label={renderLabel("Select Category")}
                      variant="outlined"
                      fullWidth
                      error={Boolean(errors.category && touched.category)}
                      helperText={<ErrorMessage name="category" />}
                    >
                      {jobCategories?.map((item) => (
                        <MenuItem value={item?.id}>{item?.title}</MenuItem>
                      ))}
                    </Field>
                  </Grid>

                  {/* Job Title */}
                  <Grid item xs={12} sm={6}>
                    <Field
                      as={TextField}
                      name="jobTitle"
                      label={renderLabel("Job Title")}
                      variant="outlined"
                      fullWidth
                      error={Boolean(errors.jobTitle && touched.jobTitle)}
                      helperText={<ErrorMessage name="jobTitle" />}
                    />
                  </Grid>

                  {/* Job Location */}
                  <Grid item xs={12} sm={6}>
                    <Field
                      as={TextField}
                      name="jobLocation"
                      label={renderLabel("Job Location")}
                      variant="outlined"
                      fullWidth
                      error={Boolean(errors.jobLocation && touched.jobLocation)}
                      helperText={<ErrorMessage name="jobLocation" />}
                    />
                  </Grid>

                  {/* Job Type */}
                  <Grid item xs={12} sm={6}>
                    <Field
                      as={TextField}
                      select
                      name="jobType"
                      label={renderLabel("Job Type")}
                      variant="outlined"
                      fullWidth
                      error={Boolean(errors.jobType && touched.jobType)}
                      helperText={<ErrorMessage name="jobType" />}
                    >
                      <MenuItem value="full time">Full Time</MenuItem>
                      <MenuItem value="part time">Part Time</MenuItem>
                      <MenuItem value="other">Other</MenuItem>
                    </Field>
                  </Grid>

                  {/* Salary Range */}
                  <Grid item xs={12} sm={6}>
                    <Field
                      as={TextField}
                      name="salaryRange"
                      label={renderLabel("Salary Range")}
                      variant="outlined"
                      fullWidth
                      error={Boolean(errors.salaryRange && touched.salaryRange)}
                      helperText={<ErrorMessage name="salaryRange" />}
                    />
                  </Grid>

                  {/* Job Description */}
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      name="jobDescription"
                      label={renderLabel("Job Description")}
                      variant="outlined"
                      multiline
                      rows={4}
                      fullWidth
                      error={Boolean(
                        errors.jobDescription && touched.jobDescription
                      )}
                      helperText={<ErrorMessage name="jobDescription" />}
                    />
                  </Grid>

                  {/* Responsibility */}
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      name="responsibility"
                      label={renderLabel("Responsibility")}
                      variant="outlined"
                      multiline
                      rows={4}
                      fullWidth
                      error={Boolean(
                        errors.responsibility && touched.responsibility
                      )}
                      helperText={<ErrorMessage name="responsibility" />}
                    />
                  </Grid>

                  {/* Qualification */}
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      name="qualification"
                      label={renderLabel("Qualification")}
                      variant="outlined"
                      multiline
                      rows={4}
                      fullWidth
                      error={Boolean(
                        errors.qualification && touched.qualification
                      )}
                      helperText={<ErrorMessage name="qualification" />}
                    />
                  </Grid>

                  {/* Total Vacancy */}
                  <Grid item xs={12} sm={6}>
                    <Field
                      as={TextField}
                      name="totalVacancy"
                      label={renderLabel("Total Vacancy")}
                      variant="outlined"
                      fullWidth
                      type="number"
                      error={Boolean(
                        errors.totalVacancy && touched.totalVacancy
                      )}
                      helperText={<ErrorMessage name="totalVacancy" />}
                    />
                  </Grid>

                  {/* Last Day to Apply */}
                  <Grid item xs={12} sm={6}>
                    <Field
                      as={TextField}
                      name="lastDayToApply"
                      label={renderLabel("Last Day to Apply")}
                      type="date"
                      variant="outlined"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        inputProps: {
                          min: new Date().toISOString().split("T")[0], // Set minimum date to today
                        },
                      }}
                      error={Boolean(
                        errors.lastDayToApply && touched.lastDayToApply
                      )}
                      helperText={<ErrorMessage name="lastDayToApply" />}
                    />
                  </Grid>
                </Grid>
                {btnLabel !== "view" && (
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    sx={{ mt: 4 }}
                    disabled={loader}
                    endIcon={<SendIcon />}
                  >
                    {loader ? "Loading ..." :  btnLabel}
                  </Button>
                )}
              </Form>
            )}
          </Formik>
        </CardContent>
      </FormCardWrapper>
    </>
  );
}

export default JobCreationForm;
