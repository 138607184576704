import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography, Container, Grid } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import toast from 'react-hot-toast';

const ComingSoon = () => {
  const [timeLeft, setTimeLeft] = useState({});
  const [email, setEmail] = useState('');

  useEffect(() => {
    // Countdown date
    const countDownDate = new Date('2024-12-31T23:59:59').getTime();

    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDownDate - now;

      // Time calculations
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Update state
      setTimeLeft({ days, hours, minutes, seconds });

      if (distance < 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  // Handle email subscription
  const handleSubscribe = () => {
   if(!email){
    return toast.error('Please fill email id')
   }
    // Implement your subscription logic here
    alert('Thank you for subscribing!');
    setEmail('');
  };

  return (
    <Box
      sx={{
        backgroundImage: 'url(https://source.unsplash.com/random/1920x1080)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh',
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        position: 'relative',
        p: 3,
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          zIndex: -1,
        },
      }}
    >
      <Container maxWidth="sm">
        <Typography variant="h2" gutterBottom>
          Coming Soon
        </Typography>
        <Typography variant="h5" gutterBottom>
          Our website is under construction. We'll be here soon with our new awesome site, subscribe to be notified!
        </Typography>

        {/* Countdown Timer */}
        <Box my={4}>
          <Grid container spacing={2} justifyContent="center">
            {['Days', 'Hours', 'Minutes', 'Seconds'].map((label, index) => (
              <Grid item xs={3} key={label}>
                <Box>
                  <Typography variant="h3">{Object.values(timeLeft)[index] ?? '00'}</Typography>
                  <Typography variant="subtitle1">{label}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Email Subscription Form */}
        <Box display="flex" justifyContent="center" alignItems="center" my={2}>
          <TextField
            variant="outlined"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{
              backgroundColor: '#fff',
              borderRadius: '4px',
              mr: 1,
              width: '70%',
            }}
          />
          <Button variant="contained" color="primary" onClick={handleSubscribe} disabled={!email}>
            Subscribe
          </Button>
        </Box>

        {/* Social Media Links */}
        <Box mt={4}>
          <FontAwesomeIcon icon={faFacebook} size="2x" color="#fff" style={{ margin: '0 10px' }} />
          <FontAwesomeIcon icon={faTwitter} size="2x" color="#fff" style={{ margin: '0 10px' }} />
          <FontAwesomeIcon icon={faInstagram} size="2x" color="#fff" style={{ margin: '0 10px' }} />
          <FontAwesomeIcon icon={faLinkedin} size="2x" color="#fff" style={{ margin: '0 10px' }} />
        </Box>
      </Container>
    </Box>
  );
};

export default ComingSoon;
