import { Typography, Menu, MenuItem, IconButton } from "@mui/material";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { logout } from "../../redux/slices/auth/authSlice";
import logo from '../../assets/images/horizontal.png'
function Navbar() {
  const { isAuthenticated } = useSelector((state) => state.auth);
  const { userProfile } = useSelector((state) => state.user);

  const [anchorEl, setAnchorEl] = useState(null);
  const [showNavbar, setShowNavbar] = useState(false);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigation = (path) => {
    navigate(path);
    handleClose();
  };

  const handleLogout = () => {
    // Clear tokens from localStorage
    localStorage.removeItem("token");

    // Dispatch the logout action to reset state
    dispatch(logout());
    handleClose();
  };

  const closeShow = ()=>{
    setShowNavbar(false)
  }

  return (
    <div className="bg-white p-0 container-fluid">
      <nav className="navbar navbar-expand-lg bg-white navbar-light shadow sticky-top p-0">
        <NavLink
          to="/"
          className="navbar-brand d-flex align-items-center text-center py-0 px-4 px-lg-5"
        >
          {/* <h1 className="m-0 text-primary">MV Hiring</h1> */}
          <img src={logo} alt="" style={{ height: "180px" , marginTop:'15px'}} />
        </NavLink>
        <button
          type="button"
          className="navbar-toggler me-4"
          onClick={handleNavbar}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse ${showNavbar ? "show" : ""}`} id="navbarCollapse">
          <div className="navbar-nav ms-auto p-4 p-lg-0">
            <NavLink
              to="/"
              className={({ isActive }) =>
                `nav-item nav-link ${isActive ? "active" : ""}`
              }
              onClick={closeShow}
            >
              Home
            </NavLink>
            <NavLink
              to="/about"
              className={({ isActive }) =>
                `nav-item nav-link ${isActive ? "active" : ""}`
              }
              onClick={closeShow}

            >
              About
            </NavLink>
            <NavLink
              to="/our-service"
              className={({ isActive }) =>
                `nav-item nav-link ${isActive ? "active" : ""}`
              }
              onClick={closeShow}

            >
              Service
            </NavLink>
            <NavLink
              to="/job-list"
              className={({ isActive }) =>
                `nav-item nav-link ${isActive ? "active" : ""}`
              }
              onClick={closeShow}

            >
              Job
            </NavLink>
            <NavLink
              to="/contact"
              className={({ isActive }) =>
                `nav-item nav-link ${isActive ? "active" : ""}`
              }
              onClick={closeShow}

            >
              Contact
            </NavLink>
            {userProfile?.userRole === 1 ? (
              <NavLink
                to="/admin"
                className={({ isActive }) =>
                  `nav-item nav-link ${isActive ? "active" : ""}`
                }
              onClick={closeShow}

              >
                Admin
              </NavLink>
            ) : null}
          </div>
          {!isAuthenticated ? (
            <NavLink to="/login" className="nav-item nav-link" onClick={closeShow} >
              Login<i className="fa fa-arrow-right ms-3"></i>
            </NavLink>
          ) : (
            <div>
              <IconButton
                onClick={handleClick}
                className="nav-item nav-link"
                sx={{ mr: 4 }}
              >
                <AccountCircleIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
              >
                <MenuItem onClick={() => handleNavigation("/applied-jobs")}>
                  Applied Jobs
                </MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </div>
          )}
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
