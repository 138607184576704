import React from 'react'
import AboutUs from './AboutUs'
import about1 from '../../assets/images/about/about-1.jpg';
import about2 from '../../assets/images/about/about-2.jpg';
import about3 from '../../assets/images/about/about-3.jpg';
import about4 from '../../assets/images/about/about-4.jpg';

export default function AboutGallery() {
    return (
        <div className="container-xxl py-5">
            <div className="container">
                <div className="row g-5 align-items-center">
                    <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                        <div className="row g-0 about-bg rounded overflow-hidden">
                            <div className="col-6 text-start">
                                <img className="img-fluid w-100" src={about1} alt="About 1" />
                            </div>
                            <div className="col-6 text-start">
                                <img 
                                    className="img-fluid" 
                                    src={about2} 
                                    style={{ width: '85%', marginTop: '15%' }} 
                                    alt="About 2" 
                                />
                            </div>
                            <div className="col-6 text-end">
                                <img 
                                    className="img-fluid" 
                                    src={about3} 
                                    style={{ width: '85%' }} 
                                    alt="About 3" 
                                />
                            </div>
                            <div className="col-6 text-end">
                                <img className="img-fluid w-100" src={about4} alt="About 4" />
                            </div>
                        </div>
                    </div>
                    <AboutUs />
                </div>
            </div>
        </div>
    )
}
